import React from "react";
import HaedatFeature from "./HaedatFeature";
import ObisFeature from "./ObisFeature";
import EventsTable from "./EventsTable";

export default function Modal({modal, viewEvents}) {
    if (modal && modal.events && modal.events.length > 0) {
        return (
            <div className="modal-wrapper">
                <div className="modal">
                    <EventsTable events={modal.events} />
                </div>
            </div>
        );
    } else if (modal && modal.haedatFeatures && modal.haedatFeatures.length > 0) {
        return (
            <div className="modal-wrapper">
                <div className="modal">
                    {
                        modal.haedatFeatures.map((feature, index) =>
                            <div key={index} className="feature-wrapper">
                                <HaedatFeature feature={feature} viewEvents={viewEvents}></HaedatFeature>
                            </div>
                        )
                    }
                </div>
            </div>
        );
    } else if (modal && modal.obisFeatures && modal.obisFeatures.length > 0) {
        return (
            <div className="modal-wrapper">
                <div className="modal">
                    {
                        modal.obisFeatures.map((feature, index) =>
                            <div key={index} className="feature-wrapper">
                                <ObisFeature feature={feature}></ObisFeature>
                            </div>
                        )
                    }
                </div>
            </div>
        );
    } else if (modal && modal.loading) {
        return (
            <div className="modal-wrapper">
                <div className="modal">
                    <div className="flex">
                        <svg className="animate-spin mr-1 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="#888888" strokeWidth="4"></circle>
                            <path className="opacity-75" fill="#333333" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                        </svg>
                        <span className="ml-2">Loading OBIS data...</span>
                    </div>
                </div>
            </div>
        );
    }
    return null;
}