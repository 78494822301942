import React, {useEffect, useRef} from "react";
import Plotly from "plotly.js";
import util from "../util/util";

export default function ObisGraph(props) {

    const containerRef = useRef(null);

    useEffect(() => {

        const fetchData = async () => {
            const res = await fetch(util.makeObisYearsUrl(props.layer));
            const data = await res.json();
            const series = {
                "occurrences": {
                    type: "bar",
                    name: "occurrences",
                    marker: { color: "#E53E3E" },
                    x: [],
                    y: []
                }
            };
            data.map(x => {
                series["occurrences"].x.push(x.year);
                series["occurrences"].y.push(x.records);
            });

            const plotData  = Object.keys(series).map(key => series[key]);

            const layout = {
                barmode: "stack",
                separators: ".",
                showlegend: true,
                bargap: 0.2,
                legend: {
                    "orientation": "h"
                },
                xaxis: {
                    tickformat: ",d"
                },
                margin: {
                    l: 50,
                    r: 50,
                    b: 50,
                    t: 50,
                    pad: 4
                },
            };
            Plotly.newPlot(containerRef.current, plotData, layout);
        };

        fetchData();

    }, [props.layer]);

    return <div ref={ containerRef }></div>
}