import React from "react";
import HaedatLayer from "./HaedatLayer";
import ObisLayer from "./ObisLayer";

export default function Layer({layer, handleDeleteLayer, viewDatasets}) {
    if (layer.type === "haedat") {
        return <HaedatLayer layer={layer} handleDeleteLayer={handleDeleteLayer} />
    } else if (layer.type === "obis") {
        return <ObisLayer layer={layer} handleDeleteLayer={handleDeleteLayer} viewDatasets={viewDatasets} />
    }
}