import React from "react";
import HaedatLayerDescription from "./HaedatLayerDescription";
import util from "../util/util";

export default function HaedatLayer(props) {
    const downloadUrl = util.makeHaedatDownloadUrl(props.layer);
    return <div className="flex mb-3">
        <div className="mr-3">
            <svg height="20" width="20">
                <circle cx="10" cy="10" r="8" stroke={props.layer.color} fill={props.layer.color} fillOpacity="0.3" opacity="0.5" strokeWidth="1" />
            </svg>
        </div>
        <div>
            <h3>HAEDAT layer</h3>
            <HaedatLayerDescription layer={props.layer} />
            <p>
                <span className="text-red-600 cursor-pointer text-xs" onClick={props.handleDeleteLayer}>delete layer</span>
                <span className="text-gray-400 ml-2 mr-2">|</span>
                <a href={downloadUrl} target="_blank" className="text-red-600 cursor-pointer text-xs" rel="noopener noreferrer">download CSV</a>
            </p>
        </div>
    </div>
}