const colors = ["#f44336", "#e91e63", "#9c27b0", "#4caf50", "#3f51b5", "#ff9800", "#03a9f4", "#00bcd4", "#009688"];
const startYear = 2018;

export default [
    {
        "type": "haedat",
        "id": "haedat_001",
        "color": colors[0],
        "startYear": startYear,
        "syndrome": [{
            "id": 12,
            "name": "Aerosolized toxins effects"
        }]
    },
    {
        "type": "haedat",
        "id": "haedat_002",
        "color": colors[1],
        "startYear": startYear,
        "syndrome": [{
            "id": 1,
            "name": "ASP"
        }]
    },
    {
        "type": "haedat",
        "id": "haedat_003",
        "color": colors[2],
        "startYear": startYear,
        "syndrome": [{
            "id": 7,
            "name": "AZP"
        }]
    },
    {
        "type": "haedat",
        "id": "haedat_004",
        "color": colors[3],
        "startYear": startYear,
        "syndrome": [{
            "id": 2,
            "name": "CFP (Ciguatera Fish Poisoning)"
        }]
    },
    {
        "type": "haedat",
        "id": "haedat_005",
        "color": colors[4],
        "startYear": startYear,
        "syndrome": [{
            "id": 8,
            "name": "Cyanobacterial toxins effects"
        }]
    },
    {
        "type": "haedat",
        "id": "haedat_006",
        "color": colors[5],
        "startYear": startYear,
        "syndrome": [{
            "id": 3,
            "name": "DSP"
        }]
    },
    {
        "type": "haedat",
        "id": "haedat_007",
        "color": colors[6],
        "startYear": startYear,
        "syndrome": [{
            "id": 4,
            "name": "NSP"
        }]
    },
    {
        "type": "haedat",
        "id": "haedat_009",
        "color": colors[7],
        "startYear": startYear,
        "syndrome": [{
            "id": 6,
            "name": "PSP"
        }]
    },
    {
        "type": "haedat",
        "id": "haedat_008",
        "color": colors[8],
        "startYear": startYear,
        "syndrome": [{
            "id": 5,
            "name": "OTHER"
        }]
    },
];