import React from "react";
import util from "../util/util";
import HaedatLayerDescription from "./HaedatLayerDescription";

export default function HaedatFeature({feature, viewEvents}) {
    return (
        <div>
            <h3>HAEDAT layer</h3>
            <HaedatLayerDescription layer={feature.layer} />

            <table className="mt-2 text-xs">
                <tbody>
                    <tr><td className="pr-2">grid</td><td><a className="text-red-600" href={util.makeHaedatGridUrl(feature.gridcode)} target="_blank" rel="noreferrer noopener">{feature.gridcode}</a></td></tr>
                    <tr><td className="pr-2">region</td><td>{feature.regions && feature.regions.join(", ")}</td></tr>
                    <tr><td className="pr-2">country</td><td>{feature.countries.join(", ")}</td></tr>
                    <tr><td className="pr-2">syndromes</td><td>{feature.syndromes.filter(x => x).join(", ")}</td></tr>
                    <tr><td className="pr-2">events</td><td><span className="text-red-600 cursor-pointer" onClick={() => viewEvents(feature)}>{feature.events}</span></td></tr>
                    <tr><td className="pr-2">years</td><td>{feature.years}</td></tr>
                </tbody>
            </table>

        </div>
    );
};
