import config from "../config/default";

const makeHaedatQueryString = function(layer) {
    let q = {};
    if (layer.syndrome && layer.syndrome.length > 0) {
        q.syndrome = layer.syndrome.map(x => x.id).join(",");
    }
    if (layer.country && layer.country.length > 0) {
        q.country = layer.country.map(x => x.id).join(",");
    }
    if (layer.region && layer.region.length > 0) {
        q.region = layer.region.map(x => x.id).join(",");
    }
    if (layer.affected) {
        q.affected = Object.entries(layer.affected).filter(([key, value]) => { return value}).map(x => x[0]).join(",");
    }
    if (layer.nature) {
        q.nature = Object.entries(layer.nature).filter(([key, value]) => { return value}).map(x => x[0]).join(",");
    }
    if (layer.startYear) q.startyear = layer.startYear;
    if (layer.endYear) q.endyear = layer.endYear;
    if (layer.gridcode) q.gridcode = layer.gridcode;
    return Object.keys(q).map(key => key + "=" + q[key]).join("&");
};

const makeObisQueryString = function(layer) {
    const q = {
        taxonid: layer.taxon.id
    };
    if (layer.keepKeys) {
        q.keepKeys = layer.keepKeys;
    }
    if (layer.geotile) {
        q.geotile = layer.geotile;
    }
    if (layer.grid) {
        q.grid = layer.grid;
    }
    if (layer.points) {
        q.points = layer.points;
    }
    if (layer.geometry) {
        q.geometry = layer.geometry;
    }
    if (layer.startYear) {
        q.startdate = `${layer.startYear}-01-01`;
    }
    if (layer.endYear) {
        q.enddate = `${layer.startYear}-12-31`;
    }
    return Object.keys(q).map(key => key + "=" + q[key]).join("&");
};

const makeHaedatGeoUrl = function(layer) {
    return "/api/events/geo?" + makeHaedatQueryString(layer);
};

const makeHaedatEventsUrl = function(layer) {
    return "/api/events?" + makeHaedatQueryString(layer);
};

const makeHaedatYearsUrl = function(layer) {
    return "/api/events/years?" + makeHaedatQueryString(layer);
};

const makeHaedatDownloadUrl = function(layer) {
    return "/api/events/csv?" + makeHaedatQueryString(layer);
};

const makeObisYearsUrl = function(layer) {
    return config["obis_api"] + "statistics/years?" + makeObisQueryString(layer);
};

const makeObisDatasetsUrl = function(layer) {
    return config["obis_api"] + "dataset?" + makeObisQueryString(layer);
};

const makeObisMapperUrl = function(layer, key) {
    return config["obis_mapper"] + "?" + makeObisQueryString({
        ...layer,
        geometry: key,
        points: true
    });
};

const makeHaedatGridUrl = function(grid) {
    return "http://haedat.iode.org/eventSearch.php?searchtext[grids][]=" + grid;
};

const makeHaedatEventUrl = function(event) {
    return "http://haedat.iode.org/viewEvent.php?eventID=" + event;
};

const makeObisGeoUrl = function(layer) {
    return config["obis_api"] + "occurrence/tile/{x}/{y}/{z}.mvt?" + makeObisQueryString({
        ...layer,
        keepKeys: true,
        grid: "geotile"
    });
};

const syndromeColor = function(syndrome) {
    if (syndrome === "Aerosolized toxins effects") return "#c02d1d";
    else if (syndrome === "ASP") return "#f26c21";
    else if (syndrome === "AZP") return "#edaa38";
    else if (syndrome === "CFP (Ciguatera Fish Poisoning)") return "#ebc843";
    else if (syndrome === "Cyanobacterial toxins effects") return "#a2b86c";
    else if (syndrome === "DSP") return "#1995bb";
    else if (syndrome === "NSP") return "#177799";
    else if (syndrome === "PSP") return "#b296e4";
    else if (syndrome === "OTHER") return "#cccccc";
    else return "#eeeeee";
};

export default {
    makeHaedatGeoUrl,
    makeHaedatEventUrl,
    makeHaedatEventsUrl,
    makeHaedatYearsUrl,
    syndromeColor,
    makeHaedatGridUrl,
    makeObisGeoUrl,
    makeObisYearsUrl,
    makeHaedatDownloadUrl,
    makeObisDatasetsUrl,
    makeObisMapperUrl
};