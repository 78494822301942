import React, {useState} from "react";
import Map from "./components/Map";
import SideBar from "./components/SideBar";
import Modal from "./components/Modal";
import "open-iconic/font/css/open-iconic.css";
import { types, positions, Provider as AlertProvider } from "react-alert";
import AlertTemplate from "react-alert-template-basic";
import util from "./util/util";
import initialLayers from "./util/initialLayers";
import Header from "./components/Header";
import Page from "./components/Page";

export default function App() {

    const [modal, setModal] = useState({});
    const [layers, setLayers] = useState(initialLayers);
    const [tab, setTab] = useState("add layer");
    const [about, setAbout] = useState(true);

    function  handleAddLayer(layer) {
        setLayers([...layers, layer]);
    }

    function handleDeleteLayer(index) {
        setModal({});
        if (index === null) {
            setLayers([]);
        } else {
            const n = [...layers];
            n.splice(index, 1);
            setLayers(n);
        }
    }

    async function viewDatasets(obisLayers) {
        const fixedFeatures = await Promise.all(obisLayers.map(async function(layer) {
            const url = util.makeObisDatasetsUrl(layer);
            const res = await fetch(url);
            let datasets;
            if (res.ok) {
                datasets = (await res.json()).results;
            }
            return {
                layer: layer,
                datasets: datasets
            }
        }));
        setModal({
            obisFeatures: fixedFeatures
        });
    }

    function viewEvents(feature) {
        const l = {
            ...feature.layer,
            gridcode: feature.gridcode
        };
        const url = util.makeHaedatEventsUrl(l);
        const fetchData = async () => {
            const res = await fetch(url);
            const data = await res.json();
            setModal({
               ...modal,
               events: data
            });
        };
        fetchData();
    }

    const alertOptions = {
        position: positions.TOP_RIGHT,
        timeout: 0,
        offset: "30px",
        type: types.ERROR
    };

    return (
        <AlertProvider template={AlertTemplate} {...alertOptions}>
            <div className="app">
                <Header setAbout={setAbout} />
                <div className="main">
                    <Modal modal={modal} viewEvents={viewEvents} />
                    { about && <Page setAbout={setAbout} /> }
                    <Map layers={layers} modal={modal} setModal={setModal} viewDatasets={viewDatasets} />
                    <SideBar tab={tab} layers={layers} viewDatasets={viewDatasets} handleTabChange={setTab} handeAddLayer={handleAddLayer} handleDeleteLayer={handleDeleteLayer} />
                </div>
            </div>
        </AlertProvider>
  );
}