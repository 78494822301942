import React from "react";

export default function Header({setAbout}) {
    return <div className="header">
        <h1>
            <a href="/">IOC-UNESCO Harmful Algae Information System</a>
        </h1>
        <ul className="menu">
            <li><span className="cursor-pointer" onClick={() => setAbout(true)}>About</span></li>
        </ul>
    </div>
}