import React from "react";
import natureList from "../util/natureList";

export default function AffectedOptions({handleNatureChange, value}) {

    const handleChange = function(e) {
        const {name, checked} = e.target;
        const n = {...value, [name]: checked};
        handleNatureChange({ nature: n });
    };

    return <div>
        <div className="flex">
            <label className="flex-1 inline-flex items-center">
                <input type="checkbox" name="discoloration" checked={value.discoloration} onChange={handleChange} /><span className="ml-2">{natureList.discoloration}</span>
            </label>
            <label className="flex-1 inline-flex items-center">
                <input type="checkbox" name="highphyto" checked={value.highphyto} onChange={handleChange} /><span className="ml-2">{natureList.highphyto}</span>
            </label>
        </div>
        <div className="flex">
            <label className="flex-1 inline-flex items-center">
                <input type="checkbox" name="toxins" checked={value.toxins} onChange={handleChange} /><span className="ml-2">{natureList.toxins}</span>
            </label>
            <label className="flex-1 inline-flex items-center">
                <input type="checkbox" name="massmortalities" checked={value.massmortalities} onChange={handleChange} /><span className="ml-2">{natureList.massmortalities}</span>
            </label>
        </div>
        <div className="flex">
            <label className="flex-1 inline-flex items-center">
                <input type="checkbox" name="foam" checked={value.foam} onChange={handleChange} /><span className="ml-2">{natureList.foam}</span>
            </label>
       </div>
    </div>
}