import React from "react";

export default function SideBarMenu({tab, handleTabChange, handleDeleteLayer}) {

    function listItem(name) {
        return <li onClick={() => handleTabChange(name)} className={"inline pr-3 hover:font-bold hover:text-red-600 cursor-pointer" + (name === tab ? " font-bold text-red-600" : "")}>{ name }</li>
    }

    return <div className="p-3 flex justify-between align-text-bottom">

        <div>
            <ul className="list-none">
                { listItem("layers") }
                { listItem("add layer") }
                { listItem("graphs") }
            </ul>
        </div>

        <div className="pt-1">
            <p className="text-red-600 cursor-pointer text-xs" onClick={() => handleDeleteLayer(null)}>clear all layers</p>
        </div>

    </div>
}