import React, { useRef, useEffect, useState } from "react";
import mapboxgl from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import util from "../util/util";

export default function Map({layers, modal, setModal, viewDatasets}) {

    const mapRef = useRef(null);
    const [ map, setMap ] = useState(null);

    const clickHandler = useRef();

    function fixHaedatFeature(feature) {
        let layer;
        const selectedLayers = layers.filter(layer => layer.id === feature.source);
        if (selectedLayers && selectedLayers.length === 1) {
            layer = selectedLayers[0];
        }
        return {
            // hack for https://github.com/mapbox/mapbox-gl-js/issues/2434
            ...feature.properties,
            layer: layer,
            countries: JSON.parse(feature.properties.countries),
            regions: JSON.parse(feature.properties.regions),
            syndromes: JSON.parse(feature.properties.syndromes)
        }
    }

    useEffect(() => {

        const map = new mapboxgl.Map({
            container: mapRef.current,
            zoom: 1,
            center: [20, 0]
        });

        map.addSource("background", {
            "type": "raster",
            "tiles": [
                "https://a.basemaps.cartocdn.com/light_all/{z}/{x}/{y}@2x.png"
            ],
            "tileSize": 256
        });
        map.addLayer({
            "id": "background",
            "type": "raster",
            "source": "background",
            "paint": {
                "raster-fade-duration": 100
            }
        });
        map.addControl(new mapboxgl.NavigationControl());
        setMap(map);

    }, []);

    useEffect(() => {
        if (map) {

            // remove any existing click handler and create new one

            if (clickHandler.current) map.off("click", clickHandler.current);
            clickHandler.current = async function(e) {

                // handle haedat layer clicks

                const haedatLayerIds = map.getStyle().layers.map(l => l.id).filter(x => x.startsWith("haedat"));
                const haedatFeatures = map.queryRenderedFeatures(
                    e.point,
                    { layers: haedatLayerIds }
                ).map(f => fixHaedatFeature(f));

                setModal({
                    haedatFeatures: haedatFeatures
                });

                // handle obis layer clicks, but only if no haedat layer features were clicked

                if (haedatFeatures.length === 0) {
                    setModal({
                        loading: true
                    });
                    const obisLayerIds = map.getStyle().layers.map(l => l.id).filter(x => x.startsWith("obis"));
                    const obisFeatures = map.queryRenderedFeatures(
                        e.point,
                        { layers: obisLayerIds }
                    );
                    const features = obisFeatures.map(feature => {
                        const selectedLayers = layers.filter(layer => layer.id === feature.source);
                        if (selectedLayers && selectedLayers.length === 1) {
                            const layer = selectedLayers[0];
                            const geotile = feature.properties.key;
                            return {
                                ...layer,
                                geometry: geotile
                            }
                        }
                    });
                    viewDatasets(features);
                }

            };
            map.on("click", clickHandler.current);

            map.getStyle().layers.map(x => x.id).filter(x => x !== "background").forEach(id => {
                map.removeLayer(id).removeSource(id);
            });

            layers.forEach(layer => {

                if (layer.type === "haedat") {
                    const url = util.makeHaedatGeoUrl(layer);
                    const fetchData = async () => {
                        const res = await fetch(url);
                        const data = await res.json();
                        map.addSource(layer.id, {
                            "type": "geojson",
                            "data": data
                        });
                        map.addLayer({
                            "id": layer.id,
                            "haedat": true,
                            "type": "circle",
                            "source": layer.id,
                            "paint": {
                                "circle-radius": [
                                    "interpolate", ["linear"], ["zoom"],
                                    1, [ "+", 3, [ "*", 3, [ "log10", ["get", "events"]]]],
                                    10, [ "+", 3, [ "*", 30, [ "log10", ["get", "events"]]]]
                                ],
                                "circle-color": layer.color,
                                "circle-opacity": 0.3,
                                "circle-stroke-width": 1,
                                "circle-stroke-color": layer.color,
                                "circle-stroke-opacity": 0.5
                            }
                        });

                        map.on("mousemove", layer.id, function () {
                            map.getCanvas().style.cursor = "pointer";
                        });
                        map.on("mouseleave", layer.id, function () {
                            map.getCanvas().style.cursor = "";
                        });
                    };
                    fetchData();
                }

                else if (layer.type === "obis") {
                    map.addSource(layer.id, {
                        "type": "vector",
                        "tiles": [ util.makeObisGeoUrl(layer) ]
                    });
                    map.on("mouseenter", layer.id, function () {
                        map.getCanvas().style.cursor = "pointer";
                    });
                    map.on("mouseleave", layer.id, function () {
                        map.getCanvas().style.cursor = "";
                    });
                    map.addLayer({
                        "id": layer.id,
                        "type": "fill",
                        "source": layer.id,
                        "source-layer": "grid",
                        "paint": {
                            "fill-color": layer.color,
                            "fill-opacity": 0.3
                        }
                    });
                }

            });
        }

    }, [map, layers]);

    return <div ref={ mapRef } id="map" className="bg-gray-100" />

}