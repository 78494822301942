import React from "react";
import ObisLayerDescription from "./ObisLayerDescription";

export default function ObisFeature({feature}) {
    return (
        <div>
            <h3>OBIS layer</h3>
            <ObisLayerDescription layer={feature.layer} />

            {
                feature.datasets.map(dataset =>
                    <div key={dataset.id}>
                        <table className="mt-2 text-xs">
                            <tbody>
                                <tr>
                                    <td className="pr-2 align-top">title</td>
                                    <td><a href={"https://obis.org/dataset/" + dataset.id} rel="noopener noreferrer" target="_blank" className="text-red-600">{dataset.title}</a></td>
                                </tr>
                                <tr>
                                    <td className="pr-2 align-top">records</td>
                                    <td>{dataset.records}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                )
            }

        </div>
    );
};
