import React, {useState} from "react";
import config from "../config/default.json";

export default function TaxonInput(props) {

    const [ query, setQuery ] = useState("");
    const [ suggestions, setSuggestions ] = useState([]);
    //const [ selected, setSelected ] = useState({});

    async function handleChange(value) {
        setQuery(value);
        if (value) {
            const res = await fetch(config["obis_api"] + "taxon/complete/" + value + "?hab=true");
            const data = await res.json();
            setSuggestions(data);
        } else {
            setSuggestions([])
        }
    }

    function handleSelect(taxon) {
        setQuery(taxon.scientificName);
        //setSelected(taxon);
        props.handleChange(taxon);
        setSuggestions([]);
    }

    return (
        <div className="mb-3">
            <input type="text" className="mb-1 appearance-none block w-full bg-gray-200 border border-gray-200 rounded py-2 px-2 focus:outline-none focus:bg-white" value={query} onChange={(e) => handleChange(e.target.value) } />
            <ul className="pl-3">
                {
                    suggestions && suggestions.map(taxon =>
                        <li onClick={() => handleSelect(taxon)} key={taxon.id} className="cursor-pointer hover:bg-gray-200 rounded px-1">
                            {taxon.scientificName} <span className="pl-2 text-gray-400 text-xs">{taxon.id}</span>
                        </li>
                    )
                }
            </ul>
        </div>
    );
};
