import React from "react";
import SideBarMenu from "./SideBarMenu";
import LayerList from "./LayerList";
import LayerForm from "./LayerForm";
import Graphs from "./Graphs";

export default function SideBar(props) {
    return <div id="sidebar" className="bg-white p-3">
        <SideBarMenu tab={props.tab} handleTabChange={props.handleTabChange} handleDeleteLayer={props.handleDeleteLayer} />
        <div className="p-3">
            { props.tab === "layers" &&
                <LayerList layers={props.layers} handleDeleteLayer={props.handleDeleteLayer} viewDatasets={props.viewDatasets} />
            }
            { props.tab === "add layer" &&
                <LayerForm handleAddLayer={props.handeAddLayer} />
            }
            { props.tab === "graphs" &&
                <Graphs layers={props.layers} />
            }
        </div>
    </div>
}