export default {
    humans: "Humans",
    fish: "Fish",
    naturalfish: "Natural fish",
    aquaculturefish: "Aquaculture fish",
    planktonic: "Planktonic life",
    benthic: "Benthic life",
    shellfish: "Shellfish",
    birds: "Birds",
    otherterrestrial: "Other terrestrial",
    aquaticmammals: "Aquatic mammals",
    seaweed: "Seaweed"
}