import React from "react";
import affectedList from "../util/affectedList";

export default function AffectedOptions({handleAffectedChange, value}) {

    const handleChange = function(e) {
        const {name, checked} = e.target;
        const n = {...value, [name]: checked};
        handleAffectedChange({ affected: n });
    };

    return <div>
        <div className="flex">
            <label className="flex-1 inline-flex items-center">
                <input type="checkbox" name="humans" checked={value.humans} onChange={handleChange} /><span className="ml-2">{affectedList.humans}</span>
            </label>
            <label className="flex-1 inline-flex items-center">
                <input type="checkbox" name="fish" checked={value.fish} onChange={handleChange} /><span className="ml-2">{affectedList.fish}</span>
            </label>
            <label className="flex-1 inline-flex items-center">
                <input type="checkbox" name="naturalfish" checked={value.naturalfish} onChange={handleChange} /><span className="ml-2">{affectedList.naturalfish}</span>
            </label>
        </div>
        <div className="flex">
            <label className="flex-1 inline-flex items-center">
                <input type="checkbox" name="aquaculturefish" checked={value.aquaculturefish} onChange={handleChange} /><span className="ml-2">{affectedList.aquaculturefish}</span>
            </label>
            <label className="flex-1 inline-flex items-center">
                <input type="checkbox" name="planktonic" checked={value.planktonic} onChange={handleChange} /><span className="ml-2">{affectedList.planktonic}</span>
            </label>
            <label className="flex-1 inline-flex items-center">
                <input type="checkbox" name="benthic" checked={value.benthic} onChange={handleChange} /><span className="ml-2">{affectedList.benthic}</span>
            </label>
        </div>
        <div className="flex">
            <label className="flex-1 inline-flex items-center">
                <input type="checkbox" name="shellfish" checked={value.shellfish} onChange={handleChange} /><span className="ml-2">{affectedList.shellfish}</span>
            </label>
            <label className="flex-1 inline-flex items-center">
                <input type="checkbox" name="birds" checked={value.birds} onChange={handleChange} /><span className="ml-2">{affectedList.birds}</span>
            </label>
            <label className="flex-1 inline-flex items-center">
                <input type="checkbox" name="otherterrestrial" checked={value.otherterrestrial} onChange={handleChange} /><span className="ml-2">{affectedList.otherterrestrial}</span>
            </label>
        </div>
        <div className="flex">
            <label className="flex-1 inline-flex items-center">
                <input type="checkbox" name="aquaticmammals" checked={value.aquaticmammals} onChange={handleChange} /><span className="ml-2">{affectedList.aquaticmammals}</span>
            </label>
            <label className="flex-1 inline-flex items-center">
                <input type="checkbox" name="seaweed" checked={value.seaweed} onChange={handleChange} /><span className="ml-2">{affectedList.seaweed}</span>
            </label>
            <label className="flex-1 inline-flex items-center">
            </label>
        </div>
    </div>
}