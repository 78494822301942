import React from "react";
import affectedList from "../util/affectedList";
import natureList from "../util/natureList";

export default function HaedatLayerDescription({layer}) {
    const affected = layer.affected ? Object.entries(layer.affected).filter(([key, value]) => { return value }).map(([key, value]) => affectedList[key]) : [];
    const nature = layer.nature ? Object.entries(layer.nature).filter(([key, value]) => { return value }).map(([key, value]) => natureList[key]) : [];
    
    let syndromeDescription = null;
    let countryDescription = null;
    let regionDescription = null;
    let periodDescription = null;
    let affectedDescription = null;
    let natureDescription = null;
    
    if (layer.syndrome && layer.syndrome.length > 0) {
        syndromeDescription = <span> with syndromes <strong>{ layer.syndrome.map(x => x.name).join(", ") }</strong></span>;
    }
    if (layer.country && layer.country.length > 0) {
        countryDescription = <span> in <strong>{ layer.country.map(x => x.name).join(", ") }</strong></span>;
    }
    if (layer.region && layer.region.length > 0) {
        regionDescription = <span> in <strong>{ layer.region.map(x => x.name).join(", ") }</strong></span>;
    }
    if (layer.startYear || layer.endYear) {
        periodDescription = <span> for period <strong>{ layer.startYear } - { layer.endYear }</strong></span>;
    }
    if (affected.length > 0) {
        affectedDescription = <span> with <strong>{ affected.join(", ") }</strong> affected</span>;
    }
    if (nature.length > 0) {
        natureDescription = <span> with <strong>{ nature.join(", ") }</strong></span>;
    }
    if (syndromeDescription || periodDescription || affectedDescription || countryDescription || regionDescription || natureDescription) {
        return <p>Events { syndromeDescription } { countryDescription } { regionDescription } { periodDescription } {affectedDescription} {natureDescription}</p>;
    } else {
        return <p>All events</p>;
    }
}