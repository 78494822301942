import React from "react";

export default function Page({setAbout}) {
    return <div className="page-wrapper">
        <div className="page">
            <div className="close text-red-600 cursor-pointer" onClick={() => setAbout(false)}>close</div>
            <h1 className="pb-2">About</h1>
            <p>The Harmful Algal Information System, HAIS, will when fully established consist of access to information on harmful algal events, harmful algae monitoring and management systems worldwide, current use of taxonomic names of harmful algae, and information on biogeography of harmful algal species. Supplementary components are an expert directory and a bibliography.</p>
            <p>The HAIS System is being built within the "International Oceanographic Data and Information Exchange" (IODE) of the "Intergovernmental Oceanographic Commission" (IOC) of UNESCO, and in cooperation with WoRMS, ICES, PICES, IAEA and ISSHA.</p>
            <p><b>HAIS components</b></p>
            <p>The IOC Taxonomic Reference List of Toxic Microalgae provides a reference for the use of names and information on each species of toxic microalgae. You can follow its merge into the World Register of Marine Organisms (WoRMS) here.</p>
            <p>The International Directory of Experts In Harmful Algae and Their Effects on Fisheries and Public Health is a specialized section of the IOC OceanExpert directory.</p>
            <p>The biogeography of harmful algal species, HABMAP within OBIS (with ISSHA), is in preparation.</p>
            <div className="pt-6 logos">
                <img src="logo_flanders.png" alt="Flanders logo" />
                <img src="logo_ioc.png" alt="IOC-UNESCO logo" />
            </div>
        </div>
    </div>
}