import React from "react";
import HaedatGraph from "./HaedatGraph";
import ObisGraph from "./ObisGraph";
import HaedatLayerDescription from "./HaedatLayerDescription";
import ObisLayerDescription from "./ObisLayerDescription";

export default function Graphs(props) {
    return <div>
        {
            props.layers.map((layer, index) => {
                if (layer.type === "haedat") {
                    return <div key={index}>
                        <h3>HAEDAT layer</h3>
                        <HaedatLayerDescription layer={layer} />
                        <HaedatGraph layer={layer}></HaedatGraph>
                    </div>
                } else if (layer.type === "obis") {
                    return <div key={index}>
                        <h3>OBIS layer</h3>
                        <ObisLayerDescription layer={layer} />
                        <ObisGraph layer={layer}></ObisGraph>
                    </div>
                }
            })
        }
    </div>
}