import React, {useState, useEffect} from "react";
import { CirclePicker } from "react-color";
import SelectHandle from "./SelectHandle";
import AffectedOptions from "./AffectedOptions";
import NatureOptions from "./NatureOptions";
import TaxonInput from "./TaxonInput";
import { useAlert } from "react-alert";
import affectedList from "../util/affectedList";
import natureList from "../util/natureList";

export default function LayerForm({handleAddLayer}) {

    console.log("layer form");

    const alert = useAlert();

    const [ syndromes, setSyndromes ] = useState({});
    const [ countries, setCountries ] = useState({});
    const [ regions, setRegions ] = useState({});
    const [ filters, setFilters ] = useState({
        syndrome: "",
        country: "",
        region: "",
        startYear: "",
        endYear: "",
        affected: Object.keys(affectedList).reduce((a, c) => { a[c] = false; return a; }, {}),
        nature: Object.keys(natureList).reduce((a, c) => { a[c] = false; return a; }, {}),
        color: "#e91e63",
        taxon: null
    });

    const handleHaedatChange = function(e) {
        if (e.hex) {
            setFilters({...filters, color: e.hex});
        } else if (e.affected) {
            setFilters({...filters, affected: e.affected});
        } else if (e.nature) {
            setFilters({...filters, nature: e.nature});
        } else if (e.target) {
            const {name, value} = e.target;
            setFilters({...filters, [name]: value});
        }
    };

    const handleAddHaedatLayer = function() {
        const id = "haedat_" + Math.random().toString(36).substr(2, 5);
        const layer = {
            type: "haedat",
            id: id,
            syndrome: filters.syndrome ? [{ id: parseInt(filters.syndrome), name: syndromes[parseInt(filters.syndrome)] }] : [],
            country: filters.country ? [{ id: parseInt(filters.country), name: countries[parseInt(filters.country)] }] : [],
            region: filters.region ? [{ id: parseInt(filters.region), name: regions[parseInt(filters.region)] }] : [],
            startYear: filters.startYear,
            endYear: filters.endYear,
            affected: filters.affected,
            nature: filters.nature,
            color: filters.color
        };
        handleAddLayer(layer);
    };

    const handleAddObisLayer = function() {
        const id = "obis_" + Math.random().toString(36).substr(2, 5);
        if (filters.taxon) {
            const layer = {
                type: "obis",
                id: id,
                color: filters.color,
                taxon: filters.taxon,
                startYear: filters.startYear,
                endYear: filters.endYear
            };
            handleAddLayer(layer);
        }
    };

    useEffect(() => {
        const fetchSyndromes = async () => {
            const res = await fetch("/api/syndromes");
            if (res.ok) {
                const data = await res.json();
                const s = data.reduce(function(map, obj) {
                    map[obj.id] = obj.name;
                    return map;
                }, {});
                setSyndromes(s);
            } else {
                alert.show("Error connecting to services, please contact it@iode.org")
            }
        };
        const fetchCountries = async () => {
            const res = await fetch("/api/countries");
            if (res.ok) {
                const data = await res.json();
                const s = data.reduce(function(map, obj) {
                    map[obj.id] = obj.name;
                    return map;
                }, {});
                setCountries(s);
            }
        };
        const fetchRegions = async () => {
            const res = await fetch("/api/regions");
            if (res.ok) {
                const data = await res.json();
                const s = data.reduce(function(map, obj) {
                    map[obj.id] = obj.name;
                    return map;
                }, {});
                setRegions(s);
            }
        };
        fetchSyndromes();
        fetchCountries();
        fetchRegions();
    }, [alert]);

    return <div>

        <h3>HAEDAT layer</h3>
        <div className="mb-2">
            <label className="block text-xs mb-1">Syndrome</label>
            <div className="relative">
                <select value={filters.syndrome} onChange={handleHaedatChange} className="block appearance-none w-full bg-gray-200 border border-gray-200 py-2 px-2 pr-8 rounded focus:outline-none focus:bg-white focus:border-gray-500" name="syndrome">
                    <option value="">all syndromes</option>
                    { Object.entries(syndromes).map(([id, name]) => <option key={ id } value={ id }>{ name }</option>) }
                </select>
                <SelectHandle/>
            </div>
        </div>
        <div className="mb-2">
            <label className="block text-xs mb-1">Country</label>
            <div className="relative">
                <select value={filters.country} onChange={handleHaedatChange} className="block appearance-none w-full bg-gray-200 border border-gray-200 py-2 px-2 pr-8 rounded focus:outline-none focus:bg-white focus:border-gray-500" name="country">
                    <option value="">all countries</option>
                    { Object.entries(countries).sort((a, b) => a[1] > b[1] ? 1 : -1).map(([id, name]) => <option key={ id } value={ id }>{ name }</option>) }
                </select>
                <SelectHandle/>
            </div>
        </div>
        <div className="mb-2">
            <label className="block text-xs mb-1">Region</label>
            <div className="relative">
                <select value={filters.region} onChange={handleHaedatChange} className="block appearance-none w-full bg-gray-200 border border-gray-200 py-2 px-2 pr-8 rounded focus:outline-none focus:bg-white focus:border-gray-500" name="region">
                    <option value="">all regions</option>
                    { Object.entries(regions).map(([id, name]) => <option key={ id } value={ id }>{ name }</option>) }
                </select>
                <SelectHandle/>
            </div>
        </div>
        <div className="mb-2">
            <label className="block text-xs mb-1">Affected components</label>
            <AffectedOptions handleAffectedChange={handleHaedatChange} value={filters.affected} />
        </div>
        <div className="mb-2">
            <label className="block text-xs mb-1">Event nature</label>
            <NatureOptions handleNatureChange={handleHaedatChange} value={filters.nature} />
        </div>
        <div className="mb-4 mt-4">
            <button onClick={handleAddHaedatLayer} className="bg-blue-500 hover:bg-blue-600 text-white text-xs py-2 px-3 rounded focus:outline-none" type="button">Add layer</button>
        </div>

        <h3>OBIS layer</h3>
        <div className="mb-2">
            <label className="block text-xs mb-1">Taxon</label>
            <TaxonInput handleChange={(taxon) => handleHaedatChange({target: {name: "taxon", value: taxon}})} />
        </div>
        <div className="mb-4 mt-4">
            <button onClick={handleAddObisLayer} className="bg-blue-500 hover:bg-blue-600 text-white text-xs py-2 px-3 rounded focus:outline-none" type="button">Add layer</button>
        </div>

        <h3>Time</h3>

        <div className="flex -mx-2 mb-2">
            <div className="w-1/2 px-2">
                <label className="block text-xs mb-1">Start year</label>
                <input value={filters.startYear} onChange={handleHaedatChange} className="appearance-none block w-full bg-gray-200 border border-gray-200 rounded py-2 px-2 mb-3 focus:outline-none focus:bg-white" name="startYear" type="number" placeholder="1800" />
            </div>
            <div className="w-1/2 px-2">
                <label className="block text-xs mb-1">End year</label>
                <input value={filters.endYear} onChange={handleHaedatChange} className="appearance-none block w-full bg-gray-200 border border-gray-200 rounded py-2 px-2 mb-3 focus:outline-none focus:bg-white" name="endYear" type="number" placeholder={(new Date()).getFullYear()} />
            </div>
        </div>

        <h3>Color</h3>

        <div className="mb-4 mt-4">
            <CirclePicker color={filters.color} onChangeComplete={handleHaedatChange} />
        </div>

    </div>


}