import React, {useEffect, useRef} from "react";
import Plotly from "plotly.js";
import util from "../util/util";

export default function HaedatGraph(props) {

    const containerRef = useRef(null);

    useEffect(() => {

        const fetchData = async () => {
            const res = await fetch(util.makeHaedatYearsUrl(props.layer));
            const data = await res.json();
            const series = {};
            data.results.map(x => {
                if (!x.name) {
                    x.name = "no syndrome";
                }
                if (!(x.name in series)) {
                    series[x.name] = {
                        x: [],
                        y: [],
                        type: "bar",
                        name: x.name,
                        marker: { color: util.syndromeColor(x.name) }
                    }
                }
                series[x.name].x.push(x.eventyear);
                series[x.name].y.push(x.events);
            });

            const plotData  = Object.keys(series).map(key => series[key]);

            const layout = {
                barmode: "stack",
                separators: ".",
                showlegend: true,
                bargap: 0.2,
                legend: {
                    "orientation": "h"
                },
                xaxis: {
                    tickformat: ",d"
                },
                margin: {
                    l: 50,
                    r: 50,
                    b: 50,
                    t: 50,
                    pad: 4
                },
            };
            Plotly.newPlot(containerRef.current, plotData, layout);
        };

        fetchData();

    }, [props.layer]);

    return <div ref={ containerRef }></div>
}