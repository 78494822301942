import React from "react";
import Layer from "./Layer";

export default function LayerList(props) {
    return (
        <div>
            { props.layers.map((layer, index) => <Layer layer={layer} viewDatasets={props.viewDatasets} key={index} handleDeleteLayer={() => props.handleDeleteLayer(index)}></Layer>)}
            {
                !props.layers || props.layers.length === 0 && <p>No layers.</p>
            }
        </div>
    );
}