import React from "react";
import ObisLayerDescription from "./ObisLayerDescription";

export default function ObisLayer({layer, handleDeleteLayer, viewDatasets}) {
    return <div className="flex mb-3">
        <div className="mr-3">
            <svg height="20" width="20">
                <rect x="2" y="2" width="16" height="16" stroke={layer.color} fill={layer.color} fillOpacity="0.3" opacity="0.5" strokeWidth="1" />
            </svg>
        </div>
        <div>
            <h3>OBIS layer</h3>
            <ObisLayerDescription layer={layer} />
            <p>
                <span className="text-red-600 cursor-pointer text-xs" onClick={handleDeleteLayer}>delete layer</span>
                <span className="text-gray-400 ml-2 mr-2">|</span>
                <span className="text-red-600 cursor-pointer text-xs" onClick={() => viewDatasets([layer])}>show datasets</span>
            </p>
        </div>
    </div>
}