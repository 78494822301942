import React from "react";
import util from "../util/util";

export default function EventsTable({events}) {
    return <div>
        <table className="data-table">
            <thead>
                <tr>
                    <th>Event</th>
                    <th>Grid</th>
                    <th>Year</th>
                    <th>Country</th>
                    <th>Syndromes</th>
                </tr>
            </thead>
            <tbody>
                {
                    events.map((event, index) =>
                        <tr key={index}>
                            <td><a className="text-red-600" href={util.makeHaedatEventUrl(event.eventid)} target="_blank" rel="noreferrer noopener">{event.eventname}</a></td>
                            <td>{event.gridcode}</td>
                            <td>{event.eventyear}</td>
                            <td>{event.countries.join(", ")}</td>
                            <td>{event.syndromes.join(", ")}</td>
                        </tr>
                    )
                }
            </tbody>
        </table>
    </div>
}